import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Mint from "components/Mint/Mint.js";
//import Minting from "components/Mint/Minting.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import jovi from "assets/img/jovi-circle-md.jpg";
import origins001 from "assets/img/origins001.png";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div className={classes.sectionLeft}>
      <GridContainer className={classes.description}>
        <GridItem xs={12} sm={12} md={7}>
          <h2 className={classes.title}>Origins - Minting Paused</h2>
          <div>
            <div>
              5902 unique Generative Art NFT&apos;s. Origins tells the story of
              the Pixel Vault and Metahero universe to date celebrating the
              amazing acheivements so far. Each NFT is randomly generated and is
              made up of:
            </div>
            <ul>
              <li>
                9824 randomly generated stars of different sizes. One for each
                metahero in the universe
              </li>
              <li>9678 are white and represent the generative heroes</li>
              <li>
                The remaining 144 black stars represent the core heroes, the
                black color comes from the Discord role assigned to core
                holders.
              </li>
              <li>
                11 Celestial bodies represent each planet in the Metahero
                universe and of course the 2 moons; the regular Moon DAO token
                and the infamous Dark side of the moon.
              </li>
              <li>
                Each planet is randomly generated and has a different size,
                color and unique pattern to represent the diversity of the Pixel
                Vault community.
              </li>
              <li>
                5902 artworks will be available to mint which represents the
                total number of Founders DAO tokens currently in circulation.
              </li>
            </ul>
            <div>
              {" "}
              Origins is totally FREE and is our way of giving back to the
              Metahero community. Owners of this genesis drop will get free
              future mints of our generative art projects.
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          {/* //<div className={classes.originImage}> */}
          <img src={origins001} className={classes.originImage}></img>
          {/* </div> */}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {/* <Minting /> */}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
          <img className={classes.joviImage} src={jovi}></img>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>About Jovi</h2>
          <h5 className={classes.description}>
            Hi I&apos;m Jovi and this is my gallery. My love for art started
            when I was a little girl when my father used to take me to the best
            galleries in the solar system, I used to dream of one day saving up
            enough money to buy art and hang in my own gallery. As I got older I
            realized it was much easier just to take the things I wanted rather
            than paying for them, and that is when my career as an art dealer
            started, but that is a story for another day.
          </h5>
          <h5 className={classes.description}>
            I opened the Jovi&apos;s Gallery on my home planet of Saturn after
            my first big score, art shouldn&apos;t be hidden away in some fat
            cats vault, it should be on display for the world to see. You see
            I&apos;m a liberator, not a thief. Since my humble beginings I have
            built many other galleries all over the solar system each one named
            of the 79 moons of Jupiter.
          </h5>
          <h5 className={classes.description}>
            You might be wondering where I got my name from. As a kid my father
            and i spent a lot of time hopping moons in the nearby Jovian system
            avoiding the authorities so everyone started calling me Jovi and it
            stuck.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
          <h2 className={classes.title}>About the project</h2>
          <h5 className={classes.description}>
            Jovi&apos;s gallery is the unofficial commuity gallery of the Pixel
            Vault and Metahero universe. Alongside our own generative artworks
            that tell the story of the Metaheroes we also want to launch other
            community members artworks on this platform to share your fan art
            with the world.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
