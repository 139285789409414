import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Hidden from "@material-ui/core/Hidden";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import jovi from "assets/img/jovi-circle-md.png";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Jovi's Gallery"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/Planet-bg.png").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={5}
              className={classes.joviHeaderImage}
            >
              <Hidden mdDown>
                <img
                  className={(classes.joviHeaderImage, classes.desktopOnly)}
                  src={jovi}
                ></img>
              </Hidden>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <h1 className={classes.title}>
                Welcome to Jovi&apos;s Gallery, the unofficial art house of the
                Metahero universe.
              </h1>
              <p>
                We tell the story of Pixelvault and the Metahero universe
                through special generative artworks. Each drop will be a unique
                commemerative, limited edition piece. Our aim is to be the
                communities gallery, allowing other artist the chance to launch
                on this platform.
              </p>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
